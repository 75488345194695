<template>
  <div class="container" style="background:black">
    <img src="../assets/images/welcome_top.png" alt="" class="top" />

    <div class="content">
      <img src="../assets/images/logo.png" alt="" class="logo" />
      <div class="recommend" v-if="user" @click="goHome">
        <img :src="user.avatar" alt="" class="avatar">
        <div class="recomment-content">
          <span class="nick"><span style="color:#fff">我是</span>{{ user.nickname }}</span>
          <span class="desc">来自{{ user.country }}·{{ user.city }}，快了看看我的主页</span>
          <div class="socials">
            <a class="social-item" v-for="(item, index) in user.socials" :key="index">
              <div v-if="index < 5">
                <img v-if="item.logo" class="logo" :src="item.logo" alt="">
                <img v-else class="logo" src="../assets/images/custom_link.png" alt="">
              </div>

            </a>
          </div>
        </div>
      </div>
      <div v-else style="height: 1.28rem;margin-top: 0.3rem;"></div>
      <div style="height: 0.3rem"></div>
      <a class="link_btn0" @click="register">注册</a>
      <div style="height: 0.32rem"></div>
      <a class="link_btn1" @click="login">登录</a>
      <div style="height: 0.5rem"></div>
    </div>
  </div>
</template>

<script>
import { resetRouter } from '../config/router';
import { get, post } from '../config/http';
import api from '../config/api';
var myInput;
export default {
  data() {
    return ({
      users: [],
      user: null,
      isiOS: false
    })
  },
  props: {
    // msg: String
  },
  watch: {
    $route(to, from) {
    }
  },
  beforeCreate() {
    if (global.hasLogin == true) {
      // this.$router.replace({ path: '/' })
      // resetRouter()
      return;
    }
  },
  mounted() {
    if (window.history.length == 0) {
      localStorage.setItem('clientHeight', document.documentElement.clientHeight);
      global.clientHeight = localStorage.getItem('clientHeight')
    }
    this.recommends();
    if (global.hasLogin == true) {
      // this.$router.replace({ path: '/' })
      // resetRouter()
      return;
    }
    var u = navigator.userAgent;
    this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (this.isiOS) {
      var input = document.createElement('input');
      input.style.position = 'absolute';
      input.id = '9527';
      input.type='tel';
      input.style.backgroundColor = 'red';
      input.style.top = 7 + 'px';
      input.style.left = 5 + 'px';
      input.style.height = 0 + 'px';
      input.style.width = 0 + 'px';
      input.style.opacity = 0;
      input.style.zIndex = 100;
      input.style.caretColor = '#FF9900';
      document.body.appendChild(input);
      myInput = input;
      // input.focus();
    }

    this.checkToken()
  },


  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    goHome() {
      this.$router.push(`/index/${this.user.id}`)
    },
    checkToken() {
      var token = localStorage.getItem('token');
      if (token) {
        var _this = this;
        global.token = token
        this.$get(this.$api.token, { token: token }).then(data => {
          if (data.success) {
            _this.getStep()
          }
        })
      }
    },
    getStep() {
      var _this = this
      this.$get(this.$api.frontDatas, { codes: 'profile_completed_step' }).then(data => {
        if (data.length == 0 || data[0].value == 1.0) {
          global.loginStep = 1.0
        }
        else {
          global.loginStep = data[0].value
        }
        if (global.loginStep != 2.2 || global.loginStep != 3) {
          _this.showLoginPop()
        }
      })
    },
    showLoginPop() {
      this.$modal(require('../assets/images/toast_tip.png'), '有未完成填写的资料\n完成填写后即可分享我的主页\n是否继续填写？', '继续填写', '稍后填写', () => {
        global.modal.show = false;
        this.$router.push({ path: '/login' });
      }, () => {
        global.modal.show = false;
      })
    },
    recommends() {
      var _this = this
      get(api.recommend).then(data => {
        _this.users = data
        if (data.length > 0) {
          _this.user = data[0]
        }
        _this.updateUser()
      })
    },
    updateUser() {
      var index = 0;
      var _this = this;
      this.timer = setInterval(() => {
        index++;
        if (index >= _this.users.length) {
          index = 0;
        }
        _this.user = _this.users[index];
      }, 2000)
    },
    register() {
      if (global.loginStep && global.loginStep != 3) {
        this.showLoginPop()
      }
      else {
        if (this.isiOS) {
          myInput.focus();
        }
        this.$router.push({ path: '/vip' });
      }

    },
    login() {
      if (global.loginStep && global.loginStep != 3) {
        this.showLoginPop()
      }
      else {
        if (this.isiOS) {
          myInput.focus();
        }
        this.$router.push({ path: '/login', query: { reset: 1 } });
      }

    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2rem;
}

.recommend {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 0.3rem;
  align-items: center;

  .avatar {
    width: 0.84rem;
    height: 0.84rem;
    border-radius: 0.42rem;
    object-fit: cover;
  }

  .recomment-content {
    width: 2.7rem;
    height: 1.28rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../assets/images/welcome_social_bg.png');
    margin-left: -0.26rem;
    box-sizing: border-box;
    padding-left: 0.6rem;
    padding-top: 0.24rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .nick {
      color:#FFF700FF;
      font-weight: bold;
      font-size: 0.2rem;
      line-height: 0.22rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 1.86rem;

    }

    .desc {
      font-size: 0.12rem;
      line-height: 0.2rem;
      margin-top: 0.07rem;
    }

    .socials {
      display: flex;
      flex-direction: row;
      margin-top: 0.08rem;
    }

    .logo {
      width: 0.24rem;
      height: 0.24rem;
      border-radius: 0.06rem;
      margin-right: 0.12rem;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  margin-top: 0.8rem;

  .logo {
    width: 2.8rem;
    height: 1.08rem;
  }
}
</style>